import Layout from "../components/layout-main";

/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  return (
    <Layout location={location} pageTitle="Joining Zoom">
      <section className="aw-accent">
        <div className="aw-content">
          <h1>How to Use Zoom for a Virtual Memorial</h1>
        </div>
      </section>
      <section>
        <div className="aw-content">
          <h2>
            <b>Join the Zoom meeting on your computer by downloading the app</b>
          </h2>
          <p>
            You can join the virtual memorial by downloading the Zoom app. This
            video explains how to download and install the Zoom app.
          </p>
          <iframe
            loading="lazy"
            title="Joining a Zoom meeting"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/pAMDxH_H_Cs"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          ></iframe>

          <h2>
            <b>Joining Zoom from your browser without installing the app</b>
          </h2>
          <ol>
            <li>
              Click in the Zoom link from the invitation.
              <br />
            </li>
            <li>
              Click on ‘launch meeting’ on the bottom of your screen
              <br />
              <img
                src="https://cdn.shopify.com/s/files/1/1037/6042/files/Screen_Shot_2020-07-24_at_10.41.57_AM.png?v=1595601734"
                alt=""
                width="100%"
                height=""
              />
              <br />
            </li>
            <li>
              Your computer will ask you to download Zoom. Instead select
              ‘Cancel’
              <br />
              <img
                src="https://cdn.shopify.com/s/files/1/1037/6042/files/Screen_Shot_2020-07-24_at_10.46.33_AM.png?v=1595602109"
                alt=""
                width="100%"
                height=""
              />
              <br />
            </li>
            <li>
              Then select ‘join from your browser’ on the bottom of your screen.
              <br />
              <img
                src="https://cdn.shopify.com/s/files/1/1037/6042/files/Screen_Shot_2020-07-24_at_10.50.03_AM.png?v=1595602252"
                alt=""
                width="100%"
                height=""
              />
              <br />
            </li>
            <li>
              You’re almost there. Type in your name and join the meeting.
              <br />
              <img
                src="https://cdn.shopify.com/s/files/1/1037/6042/files/Screen_Shot_2020-07-24_at_10.51.19_AM.png?v=1595602299"
                alt=""
                width="100%"
                height=""
              />
            </li>
          </ol>

          <h2>
            <strong>Join a Zoom meeting from your smart phone</strong>
          </h2>
          <p>
            You can also join from your smart phone. This video explains how to
            download and install the Zoom mobile app.
          </p>
          <iframe
            loading="lazy"
            title="Joining Zoom from your smart phone"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/lO206_NezaY?start=6"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          ></iframe>
        </div>
      </section>
    </Layout>
  );
};

export default ThisPage;
